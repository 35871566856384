import app, { db } from "../config/firebase";
import moment from "moment";
import axios from "axios";

axios.defaults.baseURL =
  "https://europe-west1-projet-homy.cloudfunctions.net/api";

// Codes
export const codesLoc = [
  "SHLMR01",
  "SHLMR02",
  "SHLMR03",
  "SHLMR04",
  "SHLMR05",
  "SHLMR06",
  "SHLMR07",
  "SHLMR08",
  "SHLMR09",
  "SHLMR10",
  "562cn5W",
  "Ate1071",
  "1461mdH",
  "1L172xs",
  "Ko3251Y",
  "U90Pm92",
  "96w9D9u",
  "NlQ3953",
  "V581b8U",
  "kO7569R",
  "0e79pB9",
  "A1702hl",
  "r9639O8",
  "q39x68R",
  "m370Ul7",
  "4IS370a",
  "0e7z23M",
  "7300Iro",
  "Y4436um",
  "54E61eI",
  "rS70c09",
  "249i0XH",
  "W31oT96",
  "S94j43g",
  "84TOo51",
  "651T9Vp",
  "E51d5H2",
  "Fos9869",
  "7986QYq",
  "4nS652j",
  "3Tr4G54",
  "95Q9wz1",
  "833qF7Q",
  "RA5865x",
  "6d006Ge",
  "u1C8K93",
  "31hM45e",
  "53mF0a6",
  "0f5EH24",
  "r3sG813",
  "EmX9497",
  "522OCd9",
  "0w747U6",
  "966n3C8",
  "392S8bF",
  "xq6J364",
  "9d7m61V",
  "mwE1826",
  "sZF7239",
  "3C262fG",
  "974Gkr0",
  "9Y98Ri2",
  "1vq04C5",
  "67k9Jz2",
  "d8742zG",
  "eX13y16",
  "n3M0p21",
  "Ge6361U",
  "q3a7Y68",
  "8Uv1T95",
  "R27G6x3",
  "dS9d759",
  "Zi9z770",
  "4V8F73r",
  "7X3o17q",
  "B7i62x2",
  "43Pw90k",
  "vAs3420",
  "55Al13e",
  "t190Yj5",
  "X225Sn4",
  "0n0O6M1",
  "5Q4p0E3",
  "0XTm373",
  "k2P169w",
  "0N2Fe51",
  "x1Y200z",
  "7KX468r",
  "M78o8o7",
  "0z572V8",
  "61Pc16Z",
  "x71p17O",
  "7M884uo",
  "z64X790",
  "aA0674M",
  "Z54421q",
  "64rh8B4",
  "n3U9432",
  "40YZp88",
  "js6P267",
  "742t8kB",
  "8c55S2A",
  "2D776ys",
  "16Q3El8",
  "x3584CW",
  "244rB5p",
  "C95rb95",
];

export const checkValidity = (value, rules) => {
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.regex) {
    isValid = rules.regex.test(value) && isValid;
  }

  if (rules.codes) {
    isValid = rules.codes.includes(value) && isValid;
  }

  return isValid;
};

// custom token login
export const customLogin = (refLoc, dispatch) => {
  // console.log("Axios function");
  axios
    .post("/login-centreville", { code: refLoc.value })
    .then((res) => {
      // console.log(res);
      app
        .auth()
        .signInWithCustomToken(res.data.token)
        .then((res) => {
          // console.log("Auth success: ", res.data);
          dispatch({ type: "SET_LOADING", loading: false });
        })
        .catch((err) => {
          console.log("auth error: ", err);
          dispatch({ type: "SET_LOADING", loading: false });
        });
    })
    .catch((err) => {
      console.log("axios error: ", err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Anon Login
export const anonLogin = (dispatch) => {
  app
    .auth()
    .signInAnonymously()
    .then((resp) => {
      console.log("Login successful");
      dispatch({ type: "SET_LOADING", loading: false });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Add new locataire
export const addNewLoc = (refLoc, locataireID) => {
  const newLoc = {
    refLoc,
    locataireID,
    dateCreation: moment().format(),
  };
  if (refLoc !== "") {
    db.collection("locataires")
      .doc(refLoc)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  } else {
    db.collection("locataires")
      .doc(`anon-${locataireID}`)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  }
};
