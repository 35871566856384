import { db } from "../config/firebase";
import { codesLoc } from "../utils/LoginUtils";

// Load results from firebase
export const loadResults = ({ dispatch }) => {
  db.collection("locataires")
    .get()
    .then((snapshot) => {
      dispatch({ type: "SET_PARTICIPANTS", value: snapshot.size });
      // let csv = "Nom,Mobile,Réference locataire,Date\n";
      // snapshot.forEach((doc) => {
      //   csv += `${doc.data().fullName},${doc.data().numTel},${
      //     doc.data().refLoc
      //   },${doc.data().dateCreation}`;
      //   csv += "\n";
      // });
      // console.log(csv);
    })
    .catch((err) => console.log(err));

  db.collection("resultats")
    .get()
    .then((snapshot) => {
      dispatch({ type: "SET_REPONSES", value: snapshot.size });
    })
    .catch((err) => console.log(err));
};

// Load specific answers
export const loadSpecificAnswers = () => {
  let results = {};
  codesLoc.map((code) => (results[code] = { locataire: code, answer: "" }));

  const questionKey = "c9+content-1+";
  const answerValue = {
    rep1: "Oui",
    rep2: "Non",
  };

  db.collection("resultats")
    .where("cardID", "==", "c9")
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        console.log(doc.data().locataireID);
        if (doc.id.includes(questionKey))
          results[doc.data().locataireID].answer =
            answerValue[doc.data().answers.value];
      });
    })
    .then(() => {
      console.log(results);
      let csv = "Réference locataire,Réponse\n";
      Object.keys(results).map((key, index) => {
        csv += `${results[key].locataire},${results[key].answer}`;
        csv += "\n";
      });
      console.log(csv);
    })
    .catch((err) => console.log("ERROR", err));
};

// Load participants
export const loadParticipants = () => {
  let results = {};
  codesLoc.map(
    (code) => (results[code] = { locataire: code, participated: false })
  );

  db.collection("locataires")
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        if (codesLoc.includes(doc.data().refLoc)) {
          results[doc.data().refLoc].participated = true;
        }
      });
    })
    .then(() => {
      let csv = "Réference locataire,Participated\n";

      Object.keys(results).map((key, index) => {
        csv += `${results[key].locataire},${results[key].participated}`;
        csv += "\n";
      });
      console.log(csv);
    })
    .catch((err) => console.log(err));
};

export const loadViewCount = () => {
  const cardId = "i8";
  let viewCount = 0;
  let videoComments = [];

  db.collection("resultats")
    .where("cardID", "==", cardId)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        console.log(doc.data());
        viewCount = viewCount + 1;
        if (doc.data().answers.comment) {
          videoComments.push(doc.data().answers.comment);
        }
      });
    })
    .then(() => {
      console.log(`View count for ${cardId} is : ${viewCount}`);
      console.log(`Comments for ${cardId} are : ${videoComments}`);
    })
    .catch((err) => console.log(err));
};

export const loadVoteCount = () => {
  const cardId = "c9";
  let voteCount = {
    "content-1": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    // "content-1-b": {
    //   comments: [],
    //   rep1: 0,
    //   rep2: 0,
    //   rep3: 0,
    //   rep4: 0,
    //   rep5: 0,
    //   rep6: 0,
    //   rep7: 0,
    //   rep8: 0,
    //   rep9: 0,
    //   rep10: 0,
    //   rep100: 0,
    // },
    // "content-1-c": {
    //   comments: [],
    //   rep1: 0,
    //   rep2: 0,
    //   rep3: 0,
    //   rep4: 0,
    //   rep5: 0,
    //   rep6: 0,
    //   rep7: 0,
    //   rep8: 0,
    //   rep9: 0,
    //   rep10: 0,
    //   rep100: 0,
    // },
    "content-2": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-3": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-4": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-5": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-6": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
  };
  let contentId = "";

  db.collection("resultats")
    .where("cardID", "==", cardId)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        console.log(`DOC ID => `, doc.id);
        contentId = doc.id.match(/\+([^)]+)\+/)[1];
        voteCount[contentId][doc.data().answers.value] += 1;
        if (doc.data().answers.comment) {
          voteCount[contentId].comments.push(doc.data().answers.comment);
        }
        // console.log(voteCount);
      });
    })
    .then(() => {
      console.log(`FINAL COUNT => `, voteCount);
    })
    .catch((err) => console.log(err));
};

export const loadVoteCountPerGroup = () => {
  const GROUP_A = [
    "562cn5W",
    "Ate1071",
    "1461mdH",
    "1L172xs",
    "Ko3251Y",
    "U90Pm92",
    "96w9D9u",
    "NlQ3953",
    "V581b8U",
    "kO7569R",
    "0e79pB9",
    "A1702hl",
    "r9639O8",
    "q39x68R",
    "m370Ul7",
    "4IS370a",
    "0e7z23M",
    "7300Iro",
    "Y4436um",
    "54E61eI",
    "rS70c09",
    "249i0XH",
    "W31oT96",
    "S94j43g",
  ];
  const GROUP_B = [
    "84TOo51",
    "651T9Vp",
    "E51d5H2",
    "Fos9869",
    "7986QYq",
    "4nS652j",
    "3Tr4G54",
    "95Q9wz1",
    "833qF7Q",
    "RA5865x",
    "6d006Ge",
    "u1C8K93",
  ];
  const GROUP_C = [
    "31hM45e",
    "53mF0a6",
    "0f5EH24",
    "r3sG813",
    "EmX9497",
    "522OCd9",
    "0w747U6",
    "966n3C8",
    "392S8bF",
    "xq6J364",
    "9d7m61V",
    "mwE1826",
  ];
  const GROUP_D = [
    "sZF7239",
    "3C262fG",
    "974Gkr0",
    "9Y98Ri2",
    "1vq04C5",
    "67k9Jz2",
    "d8742zG",
    "eX13y16",
    "n3M0p21",
    "Ge6361U",
    "q3a7Y68",
    "8Uv1T95",
    "R27G6x3",
    "dS9d759",
    "Zi9z770",
    "4V8F73r",
    "7X3o17q",
    "B7i62x2",
    "43Pw90k",
    "vAs3420",
    "55Al13e",
    "t190Yj5",
    "X225Sn4",
    "0n0O6M1",
    "5Q4p0E3",
  ];
  const GROUP_E = [
    "0XTm373",
    "k2P169w",
    "0N2Fe51",
    "x1Y200z",
    "7KX468r",
    "M78o8o7",
    "0z572V8",
    "61Pc16Z",
    "x71p17O",
    "7M884uo",
    "z64X790",
    "aA0674M",
    "Z54421q",
    "64rh8B4",
    "n3U9432",
    "40YZp88",
    "js6P267",
    "742t8kB",
    "8c55S2A",
    "2D776ys",
    "16Q3El8",
    "x3584CW",
    "244rB5p",
    "C95rb95",
  ];

  const cardId = "c5";
  let voteCount = {
    "content-1": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-1-b": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-1-c": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-2": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-3": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-4": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-5": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
    "content-6": {
      comments: [],
      rep1: 0,
      rep2: 0,
      rep3: 0,
      rep4: 0,
      rep5: 0,
      rep6: 0,
      rep7: 0,
      rep8: 0,
      rep9: 0,
      rep10: 0,
      rep100: 0,
    },
  };
  let contentId = "";

  GROUP_E.map((locataireId) => {
    db.collection("resultats")
      .where("cardID", "==", cardId)
      .where("locataireID", "==", locataireId)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(`DOC ID => `, doc.id);
          contentId = doc.id.match(/\+([^)]+)\+/)[1];
          voteCount[contentId][doc.data().answers.value] += 1;
          if (doc.data().answers.comment) {
            voteCount[contentId].comments.push(doc.data().answers.comment);
          }
          // console.log(voteCount);
        });
      })
      .then(() => {
        console.log(`FINAL COUNT => `, voteCount);
      })
      .catch((err) => console.log(err));
  });
};
