import React from "react";

export const CalendarData = [
  {
    id: 1,
    emoji: "🤝",
    title: "Etape 1",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "",
        title: "1ère Réunion publique (Février 2022)",
        date: "",
        duration: "",
        description: "",
      },
      {
        id: 1,
        emoji: "",
        title: "Lancement concertation locative et de la co-construction",
        date: "Du 21 février 2022 au 23 avril 2022",
        duration: "",
        description:
          "Début de la concertation locative et de la co-construction avec les locataires avec l’appui de la SHLMR et de l’association Coeur Social",
      },
      {
        id: 2,
        emoji: "",
        title: "Présentation du projet de réhabilitation et résidentialisation",
        date: "6 semaines",
        duration: "",
        description:
          "Distribution de l’accord collectif avec la nature complète des travaux (y sera annexé la synthèse des choix réalisée par les locataires dans corehab sous 6 semaines)",
      },
      {
        id: 3,
        emoji: "",
        title: "Application numérique",
        date: "A déterminer",
        duration: "",
        description:
          "Présentation de l’application de co-construction, où les habitants pourront faire des choix sur le projet.",
      },
      {
        id: 4,
        emoji: "",
        title: "Accès à l’application de co-construction",
        date: "8 semaines",
        duration: "",
        description: "",
      },
      {
        id: 5,
        emoji: "",
        title: "Visite virtuelle du logement témoin",
        date: "8 semaines",
        duration: "",
        description: "",
      },
    ],
  },
  {
    id: 2,
    emoji: "📊",
    title: "Etape 2",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "",
        title:
          "Présentation de la synthèse des résultats de la concertation locative (Mai 2022)",
        date: "",
        duration: "",
        description: "",
      },
      {
        id: 1,
        emoji: "",
        title:
          "Restitution des données récoltées lors des 6 semaines de co-construction",
        date: "Le 15 mai 2022",
        duration: "",
        description: "",
      },
      {
        id: 2,
        emoji: "",
        title: "Permanance avec la MOUS",
        date: "Du 15 mai au 31 décembre 2022",
        duration: "",
        description: "",
      },
      {
        id: 3,
        emoji: "",
        title: "Visite virtuelle du logement témoin possible",
        date: "A déterminer",
        duration: "",
        description:
          "Présentation de l’application de co-construction, où les habitants pourront faire des choix sur le projet. ",
      },
      {
        id: 4,
        emoji: "",
        title: "Présentation du logement témoin physique",
        date: "3eme trimestre 2022",
        duration: "",
        description: "",
      },
    ],
  },
  {
    id: 3,
    emoji: "🔎",
    title: "Etape 3",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "",
        title:
          "Consultation des entreprises qui vont réaliser les travaux (4eme trimestre 2022)",
        date: "",
        duration: "",
        description: "",
      },
      {
        id: 1,
        emoji: "",
        title:
          "Production du dossier de consultation des entreprises par la maîtrise d’œuvre",
        date: "2 mois ",
        duration: "",
        description: "",
      },
      {
        id: 2,
        emoji: "",
        title: "Consultation des entreprises et attributions des marchés",
        date: "3 mois ",
        duration: "",
        description: "",
      },
    ],
  },
  {
    id: 4,
    emoji: "👷‍♂️",
    title: "Etape 4",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "",
        title: "La réalisation des travaux (durée 24 mois)",
        date: "",
        duration: "",
        description: "",
      },
      {
        id: 1,
        emoji: "",
        title: "Ordre de service de démarrage des travaux",
        date: "2eme trimestre 2023",
        duration: "",
        description: "",
      },
      {
        id: 2,
        emoji: "",
        title: "Fin des travaux",
        date: "2eme trimestre 2025",
        duration: "",
        description: "",
      },
      {
        id: 3,
        emoji: "",
        title: "Année de garantie de parfait achèvement",
        date: "En 2026",
        duration: "",
        description: "",
      },
    ],
  },
];
