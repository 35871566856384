import React from "react";
import "./App.css";
// Pages / Containers
import Login from "./containers/Login/Login";
import Home from "./containers/Home/Home";
import ProfileSetup from "./containers/ProfileSetup/ProfileSetup";
import Travaux from "./containers/Travaux/Travaux";
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import Resultats from "./containers/Resultats/Resultats";
// Blog
import PageOne from "./containers/Blog/BlogPages/PageOne";
// Routing
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "./config/PrivateRoute";
// Contexts
import { AuthProvider } from "./contexts/AuthContext";
import ProfileSetupContextProvider from "./contexts/ProfileSetupContext";
import ContactContextProvider from "./contexts/ContactContext";
import LoginContextProvider from "./contexts/LoginContext";
import TravauxContextProvider from "./contexts/TravauxContext";
import ResultatsContextProvider from "./contexts/ResultatsContext";
// Transitions
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";

function App() {
  const location = useLocation();
  return (
    <AuthProvider>
      {/* <div className="App">
        <p>404 NOT FOUND</p>
      </div> */}
      <ContactContextProvider>
        <LoginContextProvider>
          <ProfileSetupContextProvider>
            <TravauxContextProvider>
              <ResultatsContextProvider>
                <AnimateSharedLayout>
                  <AnimatePresence initial={false} exitBeforeEnter>
                    <Switch location={location} key={location.pathname}>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/login" component={Login} />
                      <PrivateRoute
                        exact
                        path="/profile-setup"
                        component={ProfileSetup}
                      />
                      <PrivateRoute
                        exact
                        path="/my-profile"
                        component={ProfilePage}
                      />
                      <PrivateRoute path="/travaux" component={Travaux} />
                      {/* <Route exact path="/blog/1" component={PageOne} /> */}
                      <PrivateRoute
                        exact
                        path="/resultats"
                        component={Resultats}
                      />
                    </Switch>
                  </AnimatePresence>
                </AnimateSharedLayout>
              </ResultatsContextProvider>
            </TravauxContextProvider>
          </ProfileSetupContextProvider>
        </LoginContextProvider>
      </ContactContextProvider>
    </AuthProvider>
  );
}

export default App;
