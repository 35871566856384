import React from "react";

export const travauxCardsData = [
  {
    id: "i1",
    type: "newLayout",
    titleStrong: "La résidentialisation",
    titleWeak: "",
    description:
      "La résidentialisation a pour but de définir les espaces communs attribués à la résidence et de marquer la limite avec les espaces publics. Pour connaître toutes les étapes de cette résidentialisation, cliquez sur “explorer”.",
    thumbnail: "i1-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "La résidentialisation a pour but de définir les espaces communs attribués à la résidence et de marquer la limite avec les espaces publics. Pour connaître toutes les étapes de cette résidentialisation, cliquez sur “explorer”.",
        title: "La résidentialisation",
        video: {
          url: "https://youtu.be/bW4qHHhbT_w",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: [],
  },
  {
    id: "i2",
    type: "newLayout",
    titleStrong: "Le traitement",
    titleWeak: "des déchets",
    description:
      "Les locaux poubelles actuelles seront remplacés par des locaux plus adaptés au tri sélectif et contribueront ainsi à la préservation de notre île. Pour en savoir plus, cliquez sur “explorer”.",
    thumbnail: "i2-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Les locaux poubelles actuelles seront remplacés par des locaux plus adaptés au tri sélectif et contribueront ainsi à la préservation de notre île. Pour en savoir plus, cliquez sur “explorer”.",
        title: "Le traitement des déchets",
        video: {
          url: "https://youtu.be/goOABhtySm0",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: [],
  },
  {
    id: "i3",
    type: "newLayout",
    titleStrong: "Les travaux de",
    titleWeak: "voiries et de cheminements",
    description:
      "Ces travaux vous permettront de mieux vous déplacer au sein de votre résidence. Que vous soyez à pied, en voiture, avec une poussette, à vélo ou en chaise roulante, l’important est d’arriver sain et sauf à votre logement! Pour en savoir plus sur les travaux, cliquez sur “explorer”.",
    thumbnail: "i3-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Ces travaux vous permettront de mieux vous déplacer au sein de votre résidence. Que vous soyez à pied, en voiture, avec une poussette, à vélo ou en chaise roulante, l’important est d’arriver sain et sauf à votre logement! Pour en savoir plus sur les travaux, cliquez sur “explorer”.",
        title: "Les travaux de voiries et de cheminements",
        video: {
          url: "https://youtu.be/kCu9YDgI9O8",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: [],
  },
  {
    id: "i4",
    type: "newLayout",
    titleStrong: "Aménagement",
    titleWeak: "paysager",
    description:
      "Les concepteurs du projet œuvrent aussi pour renforcer le volet paysager au sein de votre résidence. Les objectifs de cette végétalisation sont diverses : Offrir un nouveau cadre verdoyant aux locataires; créer des ilots de fraicheur et rendre plus agréable la résidence. Pour en savoir plus, cliquez sur “explorer”.",
    thumbnail: "i4-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Les concepteurs du projet œuvrent aussi pour renforcer le volet paysager au sein de votre résidence. Les objectifs de cette végétalisation sont diverses : Offrir un nouveau cadre verdoyant aux locataires; créer des ilots de fraicheur et rendre plus agréable la résidence. Pour en savoir plus, cliquez sur “explorer”.",
        title: "Aménagement paysager",
        video: {
          url: "https://youtu.be/L5TvguLSvV4",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: [],
  },
  {
    id: "i5",
    type: "newLayout",
    titleStrong: "La température",
    titleWeak: "à l’intérieur du logement",
    description:
      "Pour améliorer la température à l’intérieur de votre logement, les architectes prévoient d’agir sur les façades de votre immeuble et sur les toitures. Votre logement bénéficiera également d’aménagements dans ce sens. Pour en savoir plus, cliquez sur “explorer.”",
    thumbnail: "i5-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Pour améliorer la température à l’intérieur de votre logement, les architectes prévoient d’agir sur les façades de votre immeuble et sur les toitures. Votre logement bénéficiera également d’aménagements dans ce sens. Pour en savoir plus, cliquez sur “explorer.”",
        title: "Améliorer la température à l’intérieur du logement",
        video: {
          url: "https://youtu.be/AmQk-BTnuGM",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: [],
  },
  {
    id: "i6",
    type: "newLayout",
    titleStrong: "Les parties communes",
    titleWeak: "de votre immeuble",
    description:
      "Ces travaux consistent à remettre à neuf et moderniser le hall et les parties communes de votre immeuble: De la porte d’entrée à la cage d’escalier. L’objectif est de rendre votre cadre de vie plus agréable et plus pratique qu’il ne l’est aujourd’hui. Pour en savoir plus à ce sujet, cliquez sur “explorer”.",
    thumbnail: "i6-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Ces travaux consistent à remettre à neuf et moderniser le hall et les parties communes de votre immeuble: De la porte d’entrée à la cage d’escalier. L’objectif est de rendre votre cadre de vie plus agréable et plus pratique qu’il ne l’est aujourd’hui. Pour en savoir plus à ce sujet, cliquez sur “explorer”.",
        title: "Les parties communes de votre immeuble",
        video: {
          url: "https://youtu.be/nyqOvBUeASY",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: [],
  },
  {
    id: "i7",
    type: "newLayout",
    titleStrong: "Les logements",
    titleWeak: "",
    description:
      "Votre logement sera complètement rénové! Aux termes des travaux dans votre logement, vos portes intérieures et fenêtres seront remplacées, la peinture refaite...et des améliorations seront apportées pour un meilleur confort thermique. Pour en savoir plus, cliquez sur “explorer”.",
    thumbnail: "i7-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Votre logement sera complètement rénové! Aux termes des travaux dans votre logement, vos portes intérieures et fenêtres seront remplacées, la peinture refaite...et des améliorations seront apportées pour un meilleur confort thermique. Pour en savoir plus, cliquez sur “explorer”.",
        title: "Les logements",
        video: {
          url: "https://youtu.be/fC4Uv6sa-C4",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: [],
  },
  {
    id: "i8",
    type: "newLayout",
    titleStrong: "Les réseaux",
    titleWeak: "d’eau et de l’électricité",
    description:
      "Les travaux prévoient de revoir tout ce qui distribue ou évacue l’eau de votre immeuble mais aussi tous les réseaux de distribution de l’électricité. Pour en savoir plus, cliquez sur “explorer”.",
    thumbnail: "i8-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Les travaux prévoient de revoir tout ce qui distribue ou évacue l’eau de votre immeuble mais aussi tous les réseaux de distribution de l’électricité. Pour en savoir plus, cliquez sur “explorer”.",
        title: "Les réseaux d’eau et de l’électricité",
        video: {
          url: "https://youtu.be/ZQKtuw8DhVc",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: [],
  },
  {
    id: "c1",
    type: "newLayout",
    titleStrong: "Création",
    titleWeak: "des espaces partagés",
    description:
      "La reconfiguration des parkings au sein de la résidence a permis de retrouver des espaces dédiés aux locataires aux pignons des bâtiments D et E.",
    thumbnail: "C1-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Création des espaces partagés",
        question:
          "Quel type de jeux ou espace de détente souhaitez-vous voir dans ces espaces?",
        imageUrl: "C1-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Des jeux pour enfants.",
            value: "rep1",
            image: "C1-q1-1.svg",
          },
          {
            label: "Un terrain de pétanque.",
            value: "rep2",
            image: "C1-q1-2.svg",
          },
          {
            label: "Un Jardin.",
            value: "rep3",
            image: "C1-q1-3.png",
          },
          {
            label: "Des tables d’échec.",
            value: "rep4",
            image: "C1-q1-4.png",
          },
          {
            label: "Un espace pour la biodiversité.",
            value: "rep5",
            image: "C1-q1-5.png",
          },
          {
            label: "Un espace de détente.",
            value: "rep6",
            image: "C1-q1-6.png",
          },
          {
            label: "Un espace d’exposition et de rencontre.",
            value: "rep7",
            image: "C1-q1-7.png",
          },
          {
            label: "Un espace pour pratiquer le sport.",
            value: "rep8",
            image: "C1-q1-8.png",
          },
          {
            label: "Un espace pique-nique.",
            value: "rep9",
            image: "C1-q1-9.png",
          },
          {
            label: "De la pelouse libre.",
            value: "rep10",
            image: "C1-q1-10.png",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "C1-q1-100.png",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c2",
    type: "newLayout",
    titleStrong: "Modification des",
    titleWeak: "clôtures jardins privatifs",
    description:
      "Les logements en rez-de-chaussée du bâtiment D profitent déjà de jardins privatifs. Dans ce cas le projet prévoit le remplacement des clôtures par une clôture opaque.",
    thumbnail: "C2-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Modification des clôtures jardins privatifs",
        question:
          "Souhaitez vous la mise en place d’une clôture opaque pour délimiter votre jardin ?",
        imageUrl: "C2-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "C2-q1-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "C2-q1-2.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Modification des clôtures jardins privatifs",
        question: "Quel type de clôture pourrait jouer ce rôle ?",
        imageUrl: "C2-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Palissade métallique pleine.",
            value: "rep1",
            image: "C2-q2-1.svg",
          },
          {
            label: "Une grille.",
            value: "rep2",
            image: "C2-q2-2.svg",
          },
          {
            label: "Haie végétale.",
            value: "rep3",
            image: "C2-q2-3.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c3",
    type: "newLayout",
    titleStrong: "Création",
    titleWeak: "de jardins privatifs",
    description:
      "Le projet prévoit la création de jardins privatifs en continuité de la varangue sur les logements de rez-de-chaussée. Dans ce cas le projet prévoit la mise en place de clôtures opaques.",
    thumbnail: "C3-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Création de jardins privatifs",
        question: "Souhaitez vous un jardin privatif ?",
        imageUrl: "C3-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "C3-q1-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "C3-q1-2.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Création de jardins privatifs",
        question:
          "Souhaitez vous la mise en place d’une clôture opaque pour délimiter votre jardin ?",
        imageUrl: "C3-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "C3-q2-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "C3-q2-2.svg",
          },
        ],
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Création de jardins privatifs",
        question: "Quel type de clôture pourrait jouer ce rôle ?",
        imageUrl: "C3-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Palissade métallique ou bois pleine.",
            value: "rep1",
            image: "C3-q3-1.svg",
          },
          {
            label: "Une grille.",
            value: "rep2",
            image: "C3-q3-2.svg",
          },
          {
            label: "Haie végétale.",
            value: "rep3",
            image: "C3-q3-3.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c4",
    type: "newLayout",
    titleStrong: "Création",
    titleWeak: "de jardins partagés",
    description:
      "Création de jardins partagés, avec robinet d’eau à l’arrière des bâtiments D ET E. La résidence offre la possibilité d’avoir un local de stockage dédié en pignon du bâtiment E (outils, engrais, terreau…)",
    thumbnail: "C4-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Création de jardins partagés",
        question:
          "Serrez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
        imageUrl: "C4-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "C4-q1-1.png",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "C4-q1-2.png",
          },
          {
            label: "Oui, mais avec un suivi ponctuel.",
            value: "rep3",
            image: "C4-q1-1.png",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Création de jardins partagés",
        question:
          "Quel type de jardin partagé convient le plus à vos attentes ?",
        imageUrl: "C4-q2-1.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Jardins individuels.",
            value: "rep1",
            image: "C4-q2-1.png",
          },
          {
            label: "Jardins collectifs.",
            value: "rep2",
            image: "C4-q2-2.png",
          },
        ],
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Création de jardins partagés",
        question:
          "Quelle serait, selon vous, la forme de culture la plus sympa ?",
        imageUrl: "C4-q3-1.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Culture au sol.",
            value: "rep1",
            image: "C4-q3-1.png",
          },
          {
            label: "Carré potager.",
            value: "rep2",
            image: "C4-q3-2.png",
          },
          {
            label: "Bac surélevé.",
            value: "rep3",
            image: "C4-q3-3.png",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c5",
    type: "newLayout",
    titleStrong: "L’usage",
    titleWeak: "des locaux annexes",
    description:
      "Sur la résidence, il existe des locaux communs (anciens locaux vélos) qui sont inoccupés : en rez-de-chaussée des bâtiments A, C et E.",
    thumbnail: "C5-q1-1.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "L’usage des locaux annexes",
        question:
          "Quel type d’aménagement souhaitez-vous dans ces locaux aujourd’hui inoccupés ?",
        imageUrl: "C5-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Un espace de rencontre avec chaise et table.",
            value: "rep1",
            image: "C5-q1-1.svg",
          },
          {
            label: "Un local de rangement pour les besoins du jardin partagé.",
            value: "rep2",
            image: "C5-q1-2.png",
          },
          {
            label: "Un local vélo.",
            value: "rep3",
            image: "C5-q1-3.svg",
          },
          {
            label: "Un local poussette.",
            value: "rep4",
            image: "C5-q1-4.png",
          },
          {
            label: "Une salle de sport.",
            value: "rep5",
            image: "C5-q1-5.png",
          },
          {
            label: "Un local moto.",
            value: "rep6",
            image: "C5-q1-6.png",
          },
          {
            label: "Un espace de jeu pour les enfants.",
            value: "rep7",
            image: "C5-q1-7.png",
          },
          {
            label: "Un espace polyvalent.",
            value: "rep8",
            image: "C5-q1-8.png",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "C5-q1-9.png",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c6",
    type: "newLayout",
    titleStrong: "Personnaliser",
    titleWeak: "votre logement",
    description:
      "Le programme de réhabilitation de la résidence s’accompagne également de la rénovation de votre logement. Pour cela, nous avons besoin de connaître vos envies et vos nécessités concernant votre future salle de bain et votre future cuisine. Pour vous exprimer sur ces sujets, cliquez sur personnaliser. (*Les aménagements se feront en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.)",
    thumbnail: "C6-q1a-1.png",
    submitted: false,
    content: [
      {
        contentId: "intro-content-1",
        type: "imgTxt",
        description: (
          <>
            <p>
              Les travaux de la salle de bain prévoient le remplacement du
              carrelage au sol ainsi que la faïence.
            </p>
            <p style={{ marginTop: "1rem" }}>
              Ici vous trouverez une série de questions pour personnaliser votre
              salle de bain.
            </p>
          </>
        ),
        title: "Personnaliser votre logement",
        image: "C6-q1-INTRO.svg",
        comment: "",
      },
      {
        contentId: "content-1a",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Les travaux prévoient de remplacer les baignoires de tous les logements par des douches. Dans cet objectif, nous aimerions savoir si vous ou l’un de vos proches souffrez d’un handicap ou de difficultés de déplacement et dans ce cas, si vous avez besoin d’aménagement particulier de type kit sénior (barre de maintien, WC réhaussé, siège de douche...) dans votre salle de bain.",
        imageUrl: "C6-q1-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui, j’ai besoin d’un kit sénior.",
            value: "rep1",
            image: "C6-q1a-1.png",
          },
          {
            label: "Non, je n’ai pas besoin de kit sénior.",
            value: "rep2",
            image: "C6-q1a-2.png",
          },
        ],
      },
      {
        contentId: "content-1b",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Les travaux de la salle de bain prévoient le remplacement du carrelage au sol ainsi que la faïence. Ici vous trouverez une série de questions pour personnaliser votre salle de bain. En premier lieu, quel coloris de sol carrelé souhaitez-vous pour votre salle de bain ?",
        imageUrl: "C6-q1-INTRO-SDB.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Grand carrelage gris.",
            value: "rep1",
            image: "C6-q1b-1.png",
          },
          {
            label: "Grand carrelage sable.",
            value: "rep2",
            image: "C6-q1b-2.png",
          },
          {
            label: "Grand carrelage silver.",
            value: "rep3",
            image: "C6-q1b-3.png",
          },
        ],
      },
      {
        contentId: "content-1c",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Enfin, quel coloris de carrelage souhaitez-vous pour la faïence de votre salle de bain ?",
        imageUrl: "C6-q1c-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Couleur unie + quelques carreaux texturés.",
            value: "rep1",
            image: "C6-q1c-1.svg",
          },
          {
            label: "Couleur unie + carreaux texturés.",
            value: "rep2",
            image: "C6-q1c-2.svg",
          },
          {
            label: "Couleur unie+ quelques carreaux texturés.",
            value: "rep3",
            image: "C6-q1c-3.svg",
          },
        ],
      },
      {
        contentId: "content-1d",
        type: "radio",
        title: "Personnaliser votre logement",
        question: "Quel choix de plan de travail dans la SDB souhaitez-vous ?",
        imageUrl: "C6-q1c-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Plan de travail couleur beige.",
            value: "rep1",
            image: "C6-q1d-1.svg",
          },
          {
            label: "Plan de travail en bois.",
            value: "rep2",
            image: "C6-q1d-2.svg",
          },
          {
            label: "Plan de travail aspect béton.",
            value: "rep3",
            image: "C6-q1d-3.svg",
          },
        ],
      },
      {
        contentId: "intro-content-2",
        type: "imgTxt",
        description: (
          <>
            <p>
              Les travaux sont aussi l’occasion pour votre cuisine de refaire
              peau neuve.
            </p>
            <p style={{ marginTop: "1rem" }}>
              Ici vous trouverez une série de questions pour personnaliser votre
              cuisine.
            </p>
          </>
        ),
        title: "Personnaliser votre logement",
        image: "C6-q2-INTRO.svg",
        comment: "",
      },
      {
        contentId: "content-2a",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Les travaux sont aussi l’occasion pour votre cuisine de refaire peau neuve. Ici vous trouverez une série de questions pour personnaliser votre cuisine. En premier lieu, quel coloris de sol carrelé souhaitez-vous pour votre cuisine ?",
        imageUrl: "C6-q2a-1.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Un grand carrelage couleur gris foncé.",
            value: "rep1",
            image: "C6-q2a-1.png",
          },
          {
            label: "Un grand carrelage couleur gris clair.",
            value: "rep2",
            image: "C6-q2a-2.png",
          },
          {
            label: "Un grand carrelage couleur sable.",
            value: "rep3",
            image: "C6-q2a-3.png",
          },
        ],
      },
      {
        contentId: "content-2b",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Quelle proposition souhaitez-vous pour la crédence de votre cuisine et le plan de travail ?",
        imageUrl: "C6-q2b-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Une crédence en carrelage avec des motifs losanges.",
            value: "rep1",
            image: "C6-q2b-1.svg",
          },
          {
            label: "Une crédence en innox brossé.",
            value: "rep2",
            image: "C6-q2b-2.svg",
          },
          {
            label: "Une crédence en carrelage avec des motifs hachures.",
            value: "rep3",
            image: "C6-q2b-3.svg",
          },
        ],
      },
      {
        contentId: "intro-content-3",
        type: "imgTxt",
        description: (
          <>
            <p>
              Les travaux vont aussi revoir tous les sols de vos salon et
              chambre.
            </p>
            <p style={{ marginTop: "1rem" }}>
              Les nouveaux sols mis en place dans le salon et le ou les chambres
              seront des sols souples.
            </p>
          </>
        ),
        title: "Personnaliser votre logement",
        image: "C6-q33-INTRO.svg",
        comment: "",
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Les travaux vont aussi revoir tous les sols de vos salon et chambre. Les nouveaux sols mis en place dans le salon et le ou les chambres seront des sols souples. Quel coloris de sol souple a votre préférence parmi les propositions suivantes ?",
        imageUrl: "C6-q3-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Sol souple imitation béton.",
            value: "rep1",
            image: "C6-q3-1.svg",
          },
          {
            label: "Sol souple imitation bois clair.",
            value: "rep2",
            image: "C6-q3-2.svg",
          },
          {
            label: "Sol souple gris clair.",
            value: "rep3",
            image: "C6-q3-3.svg",
          },
        ],
      },
      {
        contentId: "intro-content-4",
        type: "imgTxt",
        description: (
          <>
            <p>
              Enfin, la dernière question de ce chapitre des travaux consacrés
              au logement, concerne le traitement des sols de tous les espaces
              extérieurs de votre logement: Balcons, varangue, loggia. (Selon
              votre cas)
            </p>
          </>
        ),
        title: "Personnaliser votre logement",
        image: "C6-q44-INTRO.svg",
        comment: "",
      },
      {
        contentId: "content-4",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Enfin, la dernière question de ce chapitre des travaux consacrés au logement, concerne le traitement des sols de tous les espaces extérieurs de votre logement: Balcons, varangue, loggia. (Selon votre cas) Quel couleur de sol carrelé souhaitez-vos sur vos espaces extérieurs ?",
        imageUrl: "C6-q4-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Un carrelage gris foncé.",
            value: "rep1",
            image: "C6-q4-1.svg",
          },
          {
            label: "Un carrelage gris clair.",
            value: "rep2",
            image: "C6-q4-2.svg",
          },
          {
            label: "Un carrelage couleur sable.",
            value: "rep3",
            image: "C6-q4-3.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c7",
    type: "newLayout",
    titleStrong: "Extension",
    titleWeak: "de la salle de bain",
    description:
      "Le projet prévoit l’extension de la salle de bain sur le séchoir. Cette configuration supprime le séchoir au profit de la salle de bain dans laquelle on trouvera la douche, le lavabo, le ballon d’eau chaude solaire et la machine à laver). Cela permet également de ventiler la salle de bain afin d’éviter l’accumulation d’humidité. Le séchoir peut être retrouvé en continuité de la varangue lorsque la varangue jouxte la chambre. Aussi ces logements profitent déjà d’un cellier pour espace de rangement.",
    thumbnail: "C7-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Extension de la salle de bain",
        question:
          "Les concepteurs proposent d’agrandir votre salle de bain en empiétant sur le séchoir. Etes vous d’accord ?",
        imageUrl: "C7-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui (Grande salle de bain).",
            value: "rep1",
            image: "C7-q1-1.svg",
          },
          {
            label: "Non (Salle de bain existante et séchoir conservé).",
            value: "rep2",
            image: "C7-q1-2.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c8",
    type: "newLayout",
    titleStrong: "Double séjour",
    titleWeak: "",
    description:
      "Les concepteurs proposent aux logements qui disposent d’un double séjour. La création d’une cloison qui permettra de créer une chambre séparée. ",
    thumbnail: "C8-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Double séjour",
        question: "Avez-vous un double séjour ?",
        imageUrl: "C8-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "C8-q1-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "C8-q1-2.svg",
            skip: "close",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Double séjour",
        question:
          "Souhaitez vous qu’une cloison soit posée afin de créer une nouvelle chambre ?",
        imageUrl: "C8-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui, je veux un petit séjour+ 1 chambre.",
            value: "rep1",
            image: "C8-q2-1.svg",
          },
          {
            label: "Non, je souhaite conserver mon double séjour.",
            value: "rep2",
            image: "C8-q2-2.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c9",
    type: "newLayout",
    titleStrong: "Atelier signalétique",
    titleWeak: "",
    description:
      "Endemik propose un atelier participatif en présence d’un artiste dont le but est de concevoir les panneaux d’entrée à la résidence. Ces panneaux seront placés à l’entrée de chaque parking sous forme de totems. Nous proposons des ateliers prévus pour Mai / Juin 2022 pour regarder de nouveau notre quartier, le transformer, garder une trace de son histoire dans le projet de réhabilitation.",
    thumbnail: "C9-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Atelier signalétique",
        question:
          "Seriez-vous prêt à vous impliquer dans l’élaboration de ces panneaux ?",
        imageUrl: "C9-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "C9-q1-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "C9-q1-2.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
];
