import React, { useContext, useEffect } from "react";
import classes from "./Resultats.module.css";
// Utils
import {
  loadResults,
  loadParticipants,
  loadSpecificAnswers,
  loadViewCount,
  loadVoteCount,
  loadVoteCountPerGroup,
} from "../../utils/ResultatsUtils";
// Context
import { ResultatsContext } from "../../contexts/ResultatsContext";
// Components
import Toolbar from "../../components/Travaux/Navigation/Toolbar/Toolbar";
import Footer from "../../components/Travaux/Footer/Footer";

import CountUp from "react-countup";

const Resultats = () => {
  const { resultatsData, dispatch } = useContext(ResultatsContext);
  const { participants, reponses } = resultatsData;

  useEffect(() => {
    // Load results
    // loadResults({ dispatch });
    // loadParticipants();
    // loadSpecificAnswers();
    // loadViewCount();
    // loadVoteCount();
    loadVoteCountPerGroup();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.Resultats}>
        <Toolbar />
        <div className={classes.Main}>
          <p className={classes.Title}>
            <span>Résultats</span> de la co-construction.
          </p>

          <div className={classes.StatsIllustration}>
            <div className={classes.Illustration}>
              <img
                style={{ width: "100%" }}
                alt="Queue"
                src={require(`../../assets/resultats/undraw_queue.svg`)}
              />
            </div>
            <div className={classes.StatsContent}>
              <h3>Nombre de participants</h3>
              <p>
                <CountUp end={147} duration={5} />
              </p>
            </div>
          </div>

          <div className={classes.StatsIllustration}>
            <div className={classes.Illustration}>
              <img
                style={{ width: "70%" }}
                alt="Queue"
                src={require(`../../assets/resultats/undraw_reponse.svg`)}
              />
            </div>
            <div className={classes.StatsContent}>
              <h3>Nombre de réponses</h3>
              <p>
                <CountUp end={1967} duration={5} />
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Resultats;
